<template>
  <div>
    <vuestic-widget :loading="loading">
      <template slot="header">
        <span class="title">International Settings</span>
      </template>
      <ValidationObserver v-slot="{ handleSubmit }" ref="settingForm">
        <form @submit.prevent="handleSubmit(updateCountries)">
          <legend><strong>International SMS Setting</strong></legend>
          <p class="mb-2">In order to text between two countries you will need to enable those countries in your Twilio console. Here is a training video on how to enable country to country text messaging via the Twilio console. </p>
          <div class="row">
            <div class="col-md-12 video-tutorial mb-2">
              <span>Learn how to enable SMS GEO Permission in your agency account. </span>
              <a href="javascript:;" class="video-link"><img height="50px" src="@/assets/images/video-play.webp" @click.prevent="openVideoModal"></a>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <span>Click the link below to choose the countries you want to enable.</span>
              <div><b><a class="text-primary" href="https://console.twilio.com/us1/develop/sms/settings/geo-permissions" target="blank">https://console.twilio.com/us1/develop/sms/settings/geo-permissions</a></b></div>
            </div>
          </div>
          <legend><strong>International Voice Setting</strong></legend>
          <p class="mb-4">Please select the countries you would like to receive phone calls from. The countries selected will be available in your widgets. The widgets will auto-detect the country where the visitor is located. </p>
          <div role="tablist">
            <b-card no-body class="mb-3" v-for="(continent, index) in continents" :key="index">
              <b-card-header header-tag="header" class="accordion-header p-1" role="tab">
                <div class="checkbox abc-checkbox abc-checkbox-primary">
                  <input type="checkbox" name="schedule" :id="`country-group-${index}`" value="Daily" v-model="continent.active" @click="toggleContinent(index)">
                  <label :for="`country-group-${index}`">
                    <span class="abc-label-text">{{continent.name}}</span>
                  </label>
                </div>
                <div class="dropdown-icon" v-b-toggle="`accordion-${index}`">
                  <i class="fa fa-caret-down"></i>
                </div>
              </b-card-header>
              <b-collapse :id="`accordion-${index}`" :accordion="`accordion-${index}`" role="tabpanel">
                <b-card-body>
                  <ul>
                    <li v-for="(country, index1) in continent.countries" :key="index1">
                      <div class="checkbox abc-checkbox abc-checkbox-primary">
                        <input type="checkbox" name="schedule" :id="`country-${index}-${index1}`" value="Daily" v-model="country.active">
                        <label :for="`country-${index}-${index1}`">
                          <span class="abc-label-text">{{country.name}}</span>
                          <span class="abc-label-text">{{country.country_code}}</span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="row">
            <div class="col-md-12 mt-3 text-right">
              <button class="btn btn-primary">Update</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-widget>
    <!-- modal show instrucion video to user-->
    <div class="modal fade" ref="videoModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg"  role="document">
        <div class="modal-content" >
          <div class="modal-header" style="display: inline-flex;">
            <h5 class="modal-title">How to Enable SMS GEO permission in your Agency Account?</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from "vuex";

export default {
  data () {
    return {
      continents: [],
    }
  },

  mounted () {
    this.getContinents()

    const modal = $(this.$refs.videoModal);
      // eslint-disable-next-line no-useless-escape
      modal.on('show.bs.modal', () => modal.find('.modal-body').html('<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/454064274?autoplay=1&loop=1&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"><\/script>'))
        .on('hidden.bs.modal', (e) => modal.find('.modal-body').html(''));
  },

  methods: {
    openVideoModal () {
      $(this.$refs.videoModal).modal('show')
    },

    getContinents() {
      this.$store.dispatch('international/get')
        .then((res) => {
          this.continents = res.data.data
        })
        .catch(() => {
        })
    },

    updateCountries() {
      const requestData = {
        countries: this.getAllCountries()
      }

      this.$store.dispatch('international/update', requestData)
        .then((res) => {
          this.continents = res.data.data
        })
        .catch(() => {
        })
    },

    getAllCountries() {
      let countries = this.continents.reduce((startValue, item) => {
        return startValue.concat(item.countries)
      }, [])
      return countries.map((item) => {
        return {
          "code": item.code,
          "status": item.active
        }
      });
    },

    toggleContinent(index) {
      if (this.continents[index])
      {
        const continent = this.continents[index];
        continent.countries.forEach( function(item, index) {
          item.active = !continent.active
        }); 
      }
    },

    handleChange() {
      this.continents.forEach( function(item, index) {
        var continentActive = false;
        item.countries.forEach( function(country, index) {
          if (country.active)
            continentActive = true;
        });
        item.active = continentActive;
      });
    }
  },
  watch: {
    continents: {
      handler() {
        this.handleChange();
      },
      deep: true
    }
  },

  computed: {
    loading() {
      return this.$store.getters['international/loading']
    }
  },
}
</script>

<style scoped lang="scss">
  .video-link {
    display: inline-block;
    margin-left: 20px;
    transition: transform .2s; /* Animation */
    &:hover{
      transform: scale(1.05);
    }
  }
  .card {
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
  }
  .accordion-header {
    background-color: #fff;;
    color: #3578c6;
    font-weight: 600;
    font-size: 22px;
    padding: 5px 20px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.active {
      background-color: #3578c6;
      color: #fff;
    }
    .abc-checkbox {
      display: inline-block;
      margin:0;
      .abc-label-text {
        color: #3578c6;
      }
    }
    .dropdown-icon {
      flex: 1 0 auto;
      text-align: right;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      .abc-checkbox {
        margin: 0;
        padding-bottom: 10px;
        border-bottom: 1px dashed #ccc;
      }
    }
  }
</style>